import React from 'react';
import StoreLayout from '../components/StoreLayout/StoreLayout';
import SEO from '../components/SEO/SEO';
import Newsletter from '../components/Newsletter/Newsletter';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import '../scss/Article.scss';

const Content = ({ pageContext }) => {
  const { title, poster, content } = pageContext;

  return (
    <StoreLayout page={title.toLowerCase()} poster={poster}>
      <SEO
        title={title}
        description="Everything about Wibi Soerjadi, his history, life path and his amazing sheet music is available right here."
        keywords={[`wibi`, `soerjadi`, `wibi soerjadi`, `about`, `masterclass`, `summercourse`, `academy`, `sheet`, `music`, `sheet music`, `shop`]}
      />
      <main className="main">
        <div className="wrapper">
          <article className="article">
            <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} escapeHtml={false} />
          </article>
        </div>
      </main>
      <Newsletter />
    </StoreLayout>
  )
};

export default Content;